import { TimecodesForm } from './components/TimecodesForm';
// import { TimecodesRenderComponent } from "./components/TimecodesRenderComponent/TimecodesRenderComponent";
// import { TitleTime } from "./components/TitleAndTime";
import { Wrapper, WrapperForSlim } from './EditTimecodesPage.styled';

import { useCallback, useRef } from 'react';

// import { FormProvider, useFieldArray, useForm } from "react-hook-form";
// import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/named
import YouTube from 'react-youtube';

import { useGetTimecodesAdminQuery, videosAPI } from '@/api';
import { FullScreenLoader } from '@/components';
// import PlusBigIconBlack from "@/components/SvgIcons/PlusBigIconBlack";
import { ScrollToTopButton } from '@/components/ScrollToTop';
import { useAppSelector } from '@/hooks';
import {Title} from "@/pages/AdminPage/AdminPage.styled";
import { VideoWrapper } from '@/pages/VideoPage/VideoPage.styled';
import { parseDuration } from '@/utils';

////TODO: добавить возможность удалять некоторые таймкоды по индексу

export const EditTimecodesPage = () => {
  const { videoId } = useParams();

  const iframeWrapper = useRef<HTMLDivElement>(null);
  const ytPlayerRef = useRef<YouTube>(null);
  const vkRef = useRef<HTMLIFrameElement>(null);

  const isAuth = useAppSelector((state) => state.user.isAuth);

  const { data, isLoading } = useGetTimecodesAdminQuery({ videoPk: videoId ?? '' }, { skip: !videoId });
  const { data: video } = videosAPI.useGetMovieByIdQuery({ id: videoId ?? '' }, { skip: !isAuth });

  const goToTime = useCallback(
    (time: string | number) => {
      const numberTime = parseDuration(time as string);
      if (isNaN(numberTime)) {
        return;
      }
      if (video && video.source === 'VK' && vkRef.current) {
        // TODO разобраться с типизацией
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const playerVK = window.VK.VideoPlayer(vkRef.current);
        playerVK.seek(numberTime);

        iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        return;
      }

      ytPlayerRef.current?.internalPlayer.seekTo(numberTime, true);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  if (!data) {
    return <Title>Таймкоды еще формируются</Title>;
  }

  return (
    <Wrapper>
      {isLoading && <FullScreenLoader />}

      {data?.timecodes && (
        <TimecodesForm timecodes={data.timecodes || []} publicId={data.publicId || ''} goToTime={goToTime} />
      )}

      <div style={{ position: 'relative' }}>
        <WrapperForSlim>
          {video && (
            <VideoWrapper ref={iframeWrapper}>
              {video.source === 'YOUTUBE' && (
                <YouTube
                  videoId={video.videoId}
                  title={video.title}
                  ref={ytPlayerRef}
                  style={{ width: '100%', height: '308px' }}
                  opts={{
                    height: '308px',
                    width: '100%',
                    playerVars: {
                      autoplay: 0,
                      rel: 0,
                    },
                  }}
                />
              )}

              {video.source === 'VK' && (
                <iframe
                  ref={vkRef}
                  title={video.title}
                  src={`${video.originLink}&hd=2&js_api=1`}
                  width="100%"
                  height="308px"
                  allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                ></iframe>
              )}
            </VideoWrapper>
          )}
        </WrapperForSlim>
      </div>
      <ScrollToTopButton />
    </Wrapper>
  );
};
