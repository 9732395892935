import styled from 'styled-components';

import { theme } from '@/styles';

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
`;

export const TimecodesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-start;
  align-items: flex-start;

  /* height: 700px;
  overflow-y: scroll; */

  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 15px; /* ширина scrollbar */
  }
  ::-webkit-scrollbar-track {
    background: transparent; /* цвет дорожки */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px; /* закругления плашки */
    background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
  }
`;

export const Wrapper = styled.div`
  position: relative;
  /* width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start; */
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
`;

export const VideoTimecodesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
`;

export const WrapperForSlim = styled.div`
  position: fixed;
  right: 5%;
  width: 33%;
`;

export const TimecodesButtonWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;
