import { theme } from '../../../styles/theme';

import styled from 'styled-components';

export const InputWrapper = styled.input`
  width: 100%;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 12px 16px;
  color: #e4e4ff;
  transition: all 0.3s;
  border: 2px solid #62548b;

  @media (max-width: 480px) {
    font-size: 15px;
  }

  ::placeholder {
    color: ${theme.colors.text.white_30};
  }

  :hover {
    border: 2px solid ${theme.colors.white.white_100};
  }

  :focus {
    border: 2px solid ${theme.colors.white.white_100};
  }

    :disabled {
        border: 2px solid ${theme.colors.white.white_30};
    }
`;

export const TextAreaWrapper = styled.textarea`
  width: 100%;
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 12px 5px 12px 16px;
  color: #e4e4ff;
  transition: all 0.3s;
  border: 2px solid #62548b;
  resize: none;

  @media (max-width: 480px) {
    font-size: 15px;
  }

  ::placeholder {
    color: ${theme.colors.text.white_30};
  }

  :hover {
    border: 2px solid ${theme.colors.white.white_100};
  }

  :focus {
    border: 2px solid ${theme.colors.white.white_100};
  }

  ::-webkit-scrollbar {
    /* width: 12px; */
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.blue.blue_5};
    border-radius: 6px;
  }
`;
