import {IconInfo, InfoBlock, InfoText, TitleWithButtonWrapper, Wrapper} from './AdminPage.styled';

import {Content, HeaderTitle} from '../LibraryPage';

import {memo, useState} from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import {TabSwitcher} from '@/components';
import info from '@/components/SvgIcons/info.svg'

const AdminPage = () => {
  const {t} = useTranslation('pages');
  const [showInfo, setShowInfo] = useState(false)

  return (
      <Wrapper>
        <TitleWithButtonWrapper>
          <HeaderTitle>{t('account.personal')}</HeaderTitle>
          <IconInfo onMouseOver={()=>setShowInfo(true)}
                    onMouseOut={()=>setShowInfo(false)}
                    src={info} alt="info"/>
          {showInfo &&
              <InfoBlock>
                  <InfoText>{t('account.info_text')}</InfoText>
              </InfoBlock>
          }
        </TitleWithButtonWrapper>
        <Content>
          <TabSwitcher rootPath="account/view"/>
          <Outlet/>
        </Content>
      </Wrapper>
  );
};

export default memo(AdminPage);
