type ArrowTopProps = {
  stroke: string
}

const ArrowTop = ({stroke}: ArrowTopProps) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
      <path d="M6 15L12 9L18 15" stroke={stroke ? stroke : "#E4E4FF"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default ArrowTop;
