/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useImperativeHandle, useRef } from 'react';

import ReactPlayer from 'react-player';
// import { useSearchParams } from 'react-router-dom';

// import type BaseReactPlayer from 'react-player/base';

// let timerId: number;

export const FileVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  { url: string; startsFrom?: number; height: string; setCurrentTime?: (time: number) => void }
>(({ url, height, setCurrentTime }, ref) => {
  // const [params] = useSearchParams();

  const iframe = useRef<ReactPlayer>(null);

  // const goToTimeFunc = (event: BaseReactPlayer<ReactPlayerProps>) => {
  //   event.seekTo(startsFrom, 'seconds');
  //   // const ss = event.getInternalPlayer();
  //   // ss.play();
  //   // setCurrentTime(startsFrom);
  // };

  useImperativeHandle(
    ref,
    () => {
      return {
        goToTime: (time: number) => {
          iframe.current?.seekTo(time, 'seconds');
        },
      };
    },
    [],
  );

  // const onStateChange: YouTubeProps['onStateChange'] = (event: YouTubeEvent) => {
  //   if (event.data === 1) {
  //     timerId = setInterval(async () => {
  //       const currentTime = (await iframe.current?.getCurrentTime()) ?? 0;
  //       setCurrentTime(currentTime);
  //     }, 1000);
  //   }

  //   if (event.data === 2) {
  //     clearInterval(timerId);
  //   }
  // };

  return (
    <ReactPlayer
      ref={iframe}
      url={url}
      playing={true}
      controls
      width="100%"
      height={height}
      progressInterval={1000}
      // onReady={goToTimeFunc}
      onProgress={({ playedSeconds }) => {
        setCurrentTime?.(playedSeconds);
      }}
      // onStateChange={onStateChange}
    />
  );
});

FileVideoPlayer.displayName = 'FileVideoPlayer';
