import { CardList } from '../LibraryPage';
import { VideoList } from '../PlayListOutlet/components/VideoList/VideoList';
import {
  ButtonsWrapper,
  Title,
  Text,
  Description,
  DescriptionContent,
  DescriptionImage,
  StatisticsList,
  StatisticsPanel,
  StatisticsPanelItem,
  StatisticsPanelItemText,
  TextWrapper,
  VideoListWrapper,
} from '../PlayListPage';
import { getStatisticsItems } from '../PlayListPage/helpers/getStatisticsItems';

import { memo, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import { playlistsAPI } from '@/api';
import { FullScreenLoader, ShareButton } from '@/components';
import { VideoFragmentCard } from '@/components/Card';
import SearchInVideoInput from '@/components/Shared/SearchInVideoInput/SearchInVideoInput';
import { API_SHARE_URL } from '@/constants';
import { useAppSelector, useHandlingError } from '@/hooks';
import { useActions } from '@/hooks/useActions';

const PlaylistViewPage = () => {
  const { t } = useTranslation('components');

  const { showNotification } = useActions();
  const { catchError } = useHandlingError();
  // const auth = useAppSelector((state) => state.user.isAuth);
  const [isTextCollapsed, setIsTextCollapsed] = useState(true);
  const { playlistId } = useParams();
  const isAuth = useAppSelector((state) => state.user.isAuth);

  const [params] = useSearchParams();

  const searchQueryValue = params.get('search');
  const hash = params.get('hash');

  const {
    data: playlist,
    isLoading,
    error,
  } = playlistsAPI.useGetPlaylistByIdQuery(
    { id: playlistId ?? '', params: { hash: hash || undefined } },
    { skip: !playlistId },
  );

  const [getSearchVideos, { data: searchVideos, isLoading: isSearchLoading, error: searchError }] =
    playlistsAPI.useLazyGetFullSearchInPlaylistQuery();

  useEffect(() => {
    catchError(error || searchError);
  }, [error, searchError]);

  // const setLike = async () => {
  //   if (playlist && relation && !result.isLoading) {
  //     await updateRelation({
  //       id: playlist.publicId.toString(),
  //       body: {
  //         is_liked: !relation.is_liked,
  //       },
  //     });
  //   }
  // };
  ///!! разкомментить при появлении функионала закладки
  // const setMark = async () => {
  //   if (playlist && relation && !result.isLoading) {
  //     await updateRelation({
  //       id: playlist.id.toString(),
  //       body: {
  //         is_in_bookmarks: !relation.is_in_bookmarks,
  //       },
  //     });
  //   }
  // };

  const sharedButtonHandler = useCallback(() => {
    navigator.clipboard.writeText(`${API_SHARE_URL}/playlistview/${playlistId}`);

    showNotification({
      text: t('link', { ns: 'success' }),
      severity: 'success',
    });

    //   if (playlist && auth) {
    //     updateRelation({
    //       id: playlist.publicId.toString(),
    //       body: {
    //         is_reposted: true,
    //       },
    //     });
    //   }
  }, [playlistId]);

  // useEffect(() => {
  //   const setView = async (id: string) => {
  //     await updateRelation({
  //       id,
  //       body: {
  //         is_viewed: true,
  //       },
  //     });
  //   };
  //   return () => {
  //     if (isRelSuccess && !relation.is_viewed && playlist) {
  //       setView(playlist.publicId.toString());
  //     }
  //   };
  // }, [isRelSuccess, playlist, relation]);

  const getSearchVideosHandler = useCallback(
    async (query: string) => {
      await getSearchVideos({ query, publicId: playlistId || '' });
    },
    [playlist],
  );

  return (
    <>
      {playlist && (
        <>
          <Description>
            <DescriptionImage
              src={playlist.category.image || '/images/playlist_mock.png'}
              alt={playlist.category.name}
            />
            <DescriptionContent>
              <Title>{playlist.title}</Title>
              <TextWrapper isCollapsed={isTextCollapsed}>
                <Text>
                  {isTextCollapsed ? playlist.description.slice(0, 127) : playlist.description}
                  {isTextCollapsed && playlist.description.length > 127 ? '... ' : ' '}
                  {playlist.description.length > 100 && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <span
                      onClick={() => setIsTextCollapsed(!isTextCollapsed)}
                      style={{ cursor: 'pointer', opacity: 0.6, fontSize: 12 }}
                    >
                      {isTextCollapsed ? t('more') : t('less')}
                    </span>
                  )}
                </Text>
              </TextWrapper>
            </DescriptionContent>
          </Description>
          {isAuth && <SearchInVideoInput placeholder={t('search_in_pl', { ns: 'components' })}
                                         getSearch={getSearchVideosHandler} />
          }
          <StatisticsPanel>
            <StatisticsList>
              {getStatisticsItems(playlist).map((item, index) => (
                <StatisticsPanelItem key={index}>
                  <StatisticsPanelItemText>{item.text}</StatisticsPanelItemText>
                  {item.icon}
                </StatisticsPanelItem>
              ))}
            </StatisticsList>
            <ButtonsWrapper>
              {!hash && <ShareButton onClick={sharedButtonHandler} />}
              {/* {auth && relation && (
                <>
                  <LikeButton isLike={relation.is_liked} onClick={setLike} />
                  <BookmarkButton choose={relation.is_in_bookmarks} onClick={setMark} /> 
                </>
              )} */}
            </ButtonsWrapper>
          </StatisticsPanel>
          <VideoListWrapper>
            {isSearchLoading && <FullScreenLoader />}
            {searchVideos &&
              searchQueryValue &&
              (searchVideos.length ? (
                // <VideoList videos={searchVideos} videoSettings={{ withTime: true }} />
                <CardList>
                  {searchVideos.map((fragment) =>
                    fragment.cues.map((cue, i) => (
                      <Link to={`watch/${fragment.publicId}/?t=${cue.timestampLink}`} key={fragment.publicId + i}>
                        <VideoFragmentCard fragment={cue} videoPreview={fragment.thumbnailUrl} />
                      </Link>
                    )),
                  )}
                </CardList>
              ) : (
                <Title>{t('main.no_result', { ns: 'pages' })}</Title>
              ))}
            {!searchQueryValue && <VideoList videos={playlist.videos} />}
          </VideoListWrapper>
        </>
      )}
      {isLoading && <FullScreenLoader />}
    </>
  );
};

export default memo(PlaylistViewPage);
