import {
  ButtonTab,
  InfoBlockPlaylistMaterial,
  Inner,
  Panel,
  TabWrapper,
  TextTab,
  Title,
  VideoWrapper
} from './VideoPage.styled';

import Container from '../../components/Shared/Container/Container';
import FullScreenLoader from '../../components/Shared/FullScreenLo/FullScreenLoader';

import {memo, useCallback, useEffect, useRef, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {useParams, useSearchParams} from 'react-router-dom';
// eslint-disable-next-line import/named
import YouTube from 'react-youtube';

import {videosAPI} from '@/api';
import {FileVideoPlayer} from '@/components';
import {VideoFragmentCard} from "@/components/Card";
import SearchInVideoInput from "@/components/Shared/SearchInVideoInput/SearchInVideoInput";
import {useActions, useAppSelector, useHandlingError, useMatchMedia} from '@/hooks';
import {InfoText} from "@/pages/AdminPage/AdminPage.styled";
import {CardList} from "@/pages/LibraryPage";
import {Quiz} from "@/pages/QuizPage/Quiz";
import {Summary} from "@/pages/VideoPage/components/Summary";
import {Timecodes} from "@/pages/VideoPage/components/Timecodes";
import {Text} from "@/pages/VideoPage/components/Timecodes.styled";

const PublicVideoPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { catchError } = useHandlingError();
  const [params, setParams] = useSearchParams();
  const isTablet = useMatchMedia('(max-width: 768px)');
  const [tab, setTab] = useState(1);
  const [showInfo, setShowInfo] = useState(false)

  const iframe = useRef<{ goToTime: (time: number) => void } | null>(null);
  const iframeWrapper = useRef<HTMLDivElement>(null);

  const isAuth = useAppSelector((state) => state.user.isAuth);
  const search = params.get('search');

  const { showNotification } = useActions();

  const {
    data: video,
    isLoading,
    error: videoError,
  } = videosAPI.useGetMovieByIdQuery({ id: id ?? '' }, { skip: !isAuth });

  const [getSearchInVideo, { data: searchInVideo, isLoading: isSearchLoading, error: searchError }] =
    videosAPI.useLazyGetFullSearchInVideoQuery();

  const getSearchInVideoHandler = useCallback(
    async (query: string) => {
      await getSearchInVideo({ query, videoId: video?.videoId ?? '' });
    },
    [video],
  );

  useEffect(() => {
    if (!isAuth) {
      showNotification({
        text: t('w_video', { ns: 'error' }),
        severity: 'error',
      });
      setParams((params) => {
        params.set('popup', 'login');
        return params;
      });
    }
  }, [isAuth]);

  useEffect(() => {
    catchError(videoError || searchError);
  }, [videoError, searchError]);

  const goToTime = useCallback(
    (time: number) => {
      iframe.current?.goToTime(time);
      iframeWrapper.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    [video],
  );

  const height = isTablet ? '300px' : '500px';
  const disabledInput = video && video.transcriptionStatus !== 'DONE'
  const disabledTabs = video ? video.timecodesStatus === 'NOT_STARTED' : false

  return (
    <Container>
      {isLoading && <FullScreenLoader/>}
      {video && (
        <Inner>
          <VideoWrapper>
            {video.source === 'YOUTUBE' && (
              <YouTube
                videoId={video.videoId}
                title={video.title}
                style={{width: '100%', height}}
                opts={{
                  height,
                  width: '100%',
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                  },
                }}
              />
            )}

            {video.source === 'VK' && (
              <iframe
                title={video.title}
                src={`${video.originLink}&hd=2&autoplay=1`}
                width="100%"
                height={height}
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              ></iframe>
            )}

            {video.source === 'UPLOADED' && <FileVideoPlayer height={height} url={video.originLink}/>}
          </VideoWrapper>
          <Title>{video.title}</Title>
          <div>
            <Panel>
              <div style={{flex: 1}}>
                <SearchInVideoInput getSearch={getSearchInVideoHandler}
                                    disabled={disabledInput}
                />
              </div>
              <TabWrapper onMouseOver={() => setShowInfo(true)}
                          onMouseOut={() => setShowInfo(false)}
                          disabled={disabledTabs}>
                <ButtonTab disabled={disabledTabs}
                           theme={`${tab === 1 ? 'white' : 'transparent'}`}
                           onClick={() => setTab(1)}>
                  <TextTab>{t('timecodes', {ns: 'components'})}</TextTab>
                </ButtonTab>
                <ButtonTab disabled={disabledTabs}
                           theme={`${tab === 2 ? 'white' : 'transparent'}`}
                           onClick={() => setTab(2)}>
                  <TextTab>{t('summary', {ns: 'components'})}</TextTab>
                </ButtonTab>
                <ButtonTab disabled={disabledTabs}
                           theme={`${tab === 3 ? 'white' : 'transparent'}`}
                           onClick={() => setTab(3)}>
                  <TextTab>{t('quiz', {ns: 'components'})}</TextTab>
                </ButtonTab>
              </TabWrapper>
              {showInfo && disabledTabs &&
                  <InfoBlockPlaylistMaterial>
                      <InfoText>{t('info_public_pl_text', {ns: 'components'})}</InfoText>
                  </InfoBlockPlaylistMaterial>
              }
            </Panel>
            {search && searchInVideo && video && (
              <>
                {searchInVideo.length === 0 && (
                  <div style={{marginTop: '20px', marginBottom: '20px'}}>
                    <Title>{t('main.no_result', {ns: 'pages'})}</Title>
                  </div>
                )}
                <CardList>
                  {searchInVideo.map((fragment) =>
                    fragment.cues.map((cue, i) => {
                      if (fragment.publicId === video.publicId) {
                        return (
                          <VideoFragmentCard
                            fragment={cue}
                            key={fragment.publicId + i}
                            goToTime={goToTime}
                            videoPreview={fragment.thumbnailUrl}
                          />
                        );
                      }
                    }),
                  )}
                </CardList>
              </>
            )}
            {isSearchLoading && <FullScreenLoader/>}
          </div>
          {tab === 1 && (
            <Timecodes
              timecodesStatus={video && video.timecodesStatus}
              setTime={goToTime}
              id={id || ''}
            />
          )}
          {tab === 2 && (
            <Summary
              summaryStatus={video && video.summaryStatus}
              videoPublicId={id || ''}
            />
          )}
          {tab === 3 && (
            <Quiz
              quizStatus={video && video.quizzStatus}
              goToTime={goToTime}
              videoPublicId={id || ''}
            />
          )}
          {disabledInput && <Text>{t('info_public_pl_search', {ns: 'components'})}</Text>}
        </Inner>
      )}
    </Container>
  );
};

export default memo(PublicVideoPage);
