import { Footer, Header } from '../../components';
import Container from '../../components/Shared/Container/Container';

import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import { categoriesAPI } from '@/api';
import { Main, Wrapper } from '@/App.styled';
import { useAppSelector } from '@/hooks';

const MainLayout = () => {
  const [isAuth, activeModal] = useAppSelector((state) => [state.user.isAuth, state.modal.active]);
  categoriesAPI.useGetCategoriesQuery({ params: { pageSize: 14 } }, { skip: !isAuth });

  return (
    <Wrapper isFixed={Boolean(activeModal)} isMobile={isMobile}>
      <Header />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <Footer />
    </Wrapper>
  );
};

export default MainLayout;
