import { IconWrapper, StyledSearchInput, Wrapper } from './SearchInput.styled';

import SearchIcon from '../../SvgIcons/SearchIcon';

import { FocusEvent, ChangeEvent, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useActions , useDebounce , useLatest , useAppSelector ,useMatchMedia} from '@/hooks';

const SearchInput = () => {
  const { t } = useTranslation('header');
  const { setSearchStatus, setSearchValue } = useActions();
  const navigate = useNavigate();
  const location = useLocation();
  const [params, setSearch] = useSearchParams();
  const isTablet = useMatchMedia('(max-width: 767px)')

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const searchParamFunc = useLatest(() => {
    const search = params.get('search');
    return search ?? '';
  });

  const { statusSearch } = useAppSelector((state) => state.search);

  const [searchInput, setSearchInput] = useState<string>(searchParamFunc.current());
  const [, setSearchInputFocus] = useState<boolean>(false);

  const makeSearch = useDebounce(() => {
    if (searchInput) {
      setSearch({ search: searchInput, page: '1' });
      setSearchStatus(true);
    } else {
      setSearch({});
      setSearchStatus(false);
    }
    setSearchValue(searchInput);
  }, 500);

  useEffect(() => {
    if (searchParamFunc.current()) {
      setSearchStatus(true);
      setSearchValue(searchParamFunc.current());
      if (searchInputRef.current) {
        searchInputRef.current.value = searchParamFunc.current();
      }
    }
  }, []);

  useEffect(() => {
    if (!statusSearch) {
      setSearchInput(searchParamFunc.current());
    }
  }, [statusSearch]);

  const searchInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
    if (location.pathname !== '/') navigate('/');
    makeSearch();
  };

  const searchInFocusInputHandler = (event: FocusEvent<HTMLInputElement>) => {
    if (event) {
      setSearchInputFocus(true);
    }
  };
  const searchInputBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    if (event) {
      setSearchInputFocus(false);
    }
  };

  return (
    <Wrapper>
      <StyledSearchInput
        placeholder={isTablet ? t('search_mobile') : t('search')}
        onChange={searchInputHandler}
        onBlur={searchInputBlurHandler}
        onFocus={searchInFocusInputHandler}
        ref={searchInputRef}
        autoComplete="off"
        icon={
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
        }
      />
    </Wrapper>
  );
};

export default SearchInput;
