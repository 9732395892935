export const AddVideoIcon = () => {
  return (
      <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.0189 23.291L19.0189 1M19.0189 1L25 7.35294M19.0189 1L13 7.35294M9 14.7263C3.34715 15.6517 1 18.9718 1 25.6048V25.8367C1 33.8079 4.38445 37 12.8361 37L25.1639 37C33.6155 37 37 33.8079 37 25.8367V25.6048C37 18.9717 34.6556 15.6478 29 14.7214"
            stroke="#E4E4FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>


  );
};

