import { memo } from 'react';

import { VideoCard } from '@/components/Card';
import { VideoCardProps } from '@/components/Card/VideoCard/VideoCard.props';
import { CardsList } from '@/styles';
import { Video, VideoInPlaylist } from '@/types';

interface VideoListProps<T> {
  videos: T[];
  isLoading?: boolean;
  videoSettings?: Pick<VideoCardProps, 'withTime' | 'menu' | 'isPrivate'>;
  playlistId?: string;
  privateHash?: string;
}

export const VideoList = memo(
  <T extends Video | VideoInPlaylist>({ videos, videoSettings, playlistId, privateHash }: VideoListProps<T>) => {
    return (
      <CardsList>
        {videos.map((video) => (
          <VideoCard
            key={video.publicId}
            movie={video}
            to={`watch${playlistId ? '/' + playlistId + '/' : '/'}${video.publicId}?t=${video.startsFrom ?? 0}${
              privateHash ? `&linkHash=${privateHash}` : ''
            }`}
            {...videoSettings}
          />
        ))}
      </CardsList>
    );
  },
);

VideoList.displayName = 'VideoList';
