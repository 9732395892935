import { ModalProps } from './Modal.props';
import { ModalStyled, Content, Overlay, ExitButtonWrapper } from './Modal.styled';

import CrossIcon from '../../../SvgIcons/CrossIcon';
import { Portal } from '../../Portal/Portal';

import {MouseEvent, useEffect} from 'react';

import { useActions, useMount, useAppSelector } from '@/hooks';

export const Modal = ({ modal, children, closeButton = false, onClose }: ModalProps) => {
  const [tokenA, activeModal] = useAppSelector((state) => [state.user, state.modal.active]);
  const { mounted } = useMount(activeModal === modal);
  // const [params, setParams] = useSearchParams();

  const { closeModal, logout } = useActions();

  useEffect(() => {
    if (!tokenA && (modal !== 'registration' || 'login')) {
      closeModal();
      logout();
    }
  }, [tokenA, modal]);

  const close = () => {
    closeModal();
    //!!not work
    // params.delete('popup');
  };

  const handleOverlayClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      close();
    }
  };


  if (mounted) {
    return (
      <Portal>
        <ModalStyled>
          <Overlay open={activeModal === modal} onClick={handleOverlayClick}/>
          <Content open={activeModal === modal} modal={activeModal} id="modal">
            <>
              {children}
              {closeButton && (
                <ExitButtonWrapper type="reset" onClick={onClose ? onClose : close}>
                  <CrossIcon />
                </ExitButtonWrapper>
              )}
            </>
          </Content>
        </ModalStyled>
      </Portal>
    );
  }
  return null;
};
