import { Button } from '../../../../components/Shared/Buttons/Button';

import styled from 'styled-components';

import { theme } from '@/styles';

type TLine = {
  isActive?: boolean;
};

export const SliderContainer = styled.div`  
  width: 100vw;
  max-width: 1400px;
  height: 100%;
  display: flex;
  gap: 40px;
  padding: 0 2vw;
  margin: 0 auto;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 16px;
    gap: 20px;
  }
  
  @media (min-width: 1920px) {
    width: 83vw;
  }
`;

interface SliderSectionProps {
  width: number;
  height: number;
}

export const SliderSection = styled.section<SliderSectionProps>`
  background-repeat: no-repeat;
  background-position: left;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

export const SliderBg = styled.div`
  background-image: url('../../../../../public/images/onboardingNew/blur-ellipses-mobile.svg');
  background-repeat: round;
  background-size: 92%;
  height: 100%;

  @media (min-width: 1400px) {
    background-image: url('../../../../../public/images/onboardingNew/blur-ellipses-bg2.png');
  }
`;

export const NavBar = styled.nav`
  width: 100%;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: end;

  @media (max-width: 576px) {
    height: 60px;
    padding: 0;
  }
`;

export const ButtonSkip = styled(Button)`
  & p {
    color: ${theme.colors.white.white_70};
    &:active {
      color: ${theme.colors.white.white_100};
    }
  }
`;

export const SlideContentWrapper = styled.div`
  overflow: hidden;
  max-height: calc(100vh - 250px);
  flex: 1;
  display: flex;
  align-items: center;
  
  & img {
    object-fit: contain;
    object-position: top;
  }

  & > div {
    height: 100%;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
  }

  & > div > div:first-child {
    width: 350px;
  }

  & > div > div:last-child {
    display: flex;
    justify-content: right;
  }

  & > div > div:last-child img {
    object-position: top right;
  }

  @media (max-width: 768px) {
    & > div {
      flex: 1;
    }

    & > div > div:first-child {
      width: 100%;
    }

    & > div > div:last-child img {
      object-position: top center;
    }
  }

  @media (min-width: 992px) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
  }

  @media (min-width: 1400px) {
    & > div {
      gap: 40px;
    }
    
    & > div > div:first-child {
      width: 400px;
    }
  }

  @media (min-width: 1920px) {
    & > div {
      gap: 40px;
    }
    
    & > div > div:first-child {
      width: 400px;
    }    
  }
`;

export const ControlsWrapper = styled.div`
  width: 100%;
  height: 150px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 576px) {
    height: 115px;
  }
`;

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Line = styled.hr<TLine>`
  display: block;
  border: 3px solid ${(props) => (props.isActive ? '#E4E4FF' : '#2B1759')};
  transition: border-color 0.5s ease;
  margin-right: 20px;
  margin-bottom: 5.56vh;
  border-radius: 10px;
  cursor: pointer;

  @media (min-width: 576px) {
    width: 15vw;
    border-radius: 2px;
  }

  @media (min-width: 992px) {
    border: 2px solid ${(props) => (props.isActive ? '#E4E4FF' : '#2B1759')};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
  
  & > button {
    padding: 12px 50px;
  }

  @media (max-width: 572px) {
    gap: 20px;
    & > button {
      width: unset;
      flex: 1;
      padding: 12px 0;
    }
  }
`;

export const ImgMobile = styled.img`
  padding: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 82px;
  position: relative;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`;

export const ImageWrapper = styled.div`
    flex: 1;
    overflow: hidden;

    @media (max-width: 576px) {
      flex: 1;
    }
`;

export const Header = styled.h1`
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 52px;
  line-height: 130%;
  color: ${theme.colors.white.white_100};
  font-family: ${theme.fonts.nunito};
    
  @media (max-width: 576px) {
    margin-bottom: 16px;
    font-size: 22px !important;
  }

  @media (max-width: 992px) {
    font-size: 26px;
  }
`;

export const TextContentWrapper = styled.div`
`;

export const TextContent = styled.p`
  font-family: ${theme.fonts.nunito};
  font-size: 26px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 30px;  
  color: ${theme.colors.label.label_light};

  @media (min-width: 576px) {
  }

  @media (min-width: 1100px) {
  }
`;

export const TextSpan = styled.span`
  font-family: ${theme.fonts.nunito};
  font-weight: 800;
  line-height: 170%;
  color: ${theme.colors.green.green_light};
  padding-left: 5px;
  padding-right: 5px;

  @media (min-width: 992px) {
  }

  @media (min-width: 1400px) {
  }
`;

export const TextFrame = styled.div`
    display: flex;
    flex-direction: column;
    width: 550px !important;
    max-width: 50%;
    justify-content: space-between;

    @media (max-width: 576px) {
        width: 100% !important;
        max-width: unset;
    }
`;

export const TextBlock = styled.p`
    font-family: ${theme.fonts.nunito};
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 30px;
    color: ${theme.colors.label.label_light};

    @media (max-width: 576px) {
      font-size: 14px;
    }

    @media (min-width: 992px) {
        font-size: 21px;
    }

    @media (min-width: 1400px) {
        font-size: 26px;
    }
`;