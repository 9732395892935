import styled from 'styled-components';

import { theme } from '@/styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
`;

export const QuizPageStyledForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
  position: relative;
`;
export const QuizBlockWrapper = styled.form`
  padding: 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.blue.blue_55};
  border-radius: 20px;
`;

export const WrapperForSlim = styled.div`
    position: fixed;
    right: 13%;
    width: 27%;
`;
