// import { SearchInput } from './SearchInput';

import { CardItem, CardList, InfoTitle, InfoWrapper } from '../LibraryPage';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { videosAPI } from '@/api';
import { FullScreenLoader, RemoveVideo } from '@/components';
import { VideoCard } from '@/components/Card';
import { useHandlingError } from '@/hooks';

export const AdminVideos = () => {
  const { t } = useTranslation('pages');
  const { catchError } = useHandlingError();
  const { data: videos, isLoading, isSuccess, error } = videosAPI.useGetMyVideosQuery({});
  // const [getMyVideos] = videosAPI.useLazyGetMyVideosQuery({});

  useEffect(() => {
    catchError(error);
  }, [error]);

  // const setSearch = async (title?: string) => {
  //   await getMyVideos({ params: { title } });
  // };

  return (
    <>
      {/* <SearchInput placeholder={t('admin.search.video')} setSearch={setSearch} /> */}
      <CardList>
        {isSuccess &&
          videos.results.map((movie) => (
            <CardItem key={movie.publicId}>
              <VideoCard movie={movie} to={`watch/${movie.publicId}`} menu />
            </CardItem>
          ))}
      </CardList>
      {isSuccess && videos.results.length === 0 && (
        <InfoWrapper>
          <InfoTitle>{t('library.no_v')}</InfoTitle>
        </InfoWrapper>
      )}
      {isLoading && <FullScreenLoader />}
      <RemoveVideo />
    </>
  );
};
