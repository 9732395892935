import { TextWrapper, Text, TextLink } from './Timecodes.styled';

import { useState } from 'react';

import {useTranslation} from "react-i18next";

export const TimecodeDiscription = ({ text }: { text: string }) => {
  const { t } = useTranslation('components');
  const [isCollapsedText, setIsCollapsedText] = useState(true);

  const onReadMoreClick = () => {
    setIsCollapsedText((p) => !p);
  };

  if (text.length < 100)
    return (
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
    );
  return (
    <TextWrapper>
      {isCollapsedText ? (
        <span>
          <Text>{`${text.slice(0, 100)}...`}</Text>
          <TextLink onClick={onReadMoreClick}>{t('timecodes_desc_more')}</TextLink>
        </span>
      ) : (
        <span>
          <Text>{text}</Text>
          <TextLink onClick={() => setIsCollapsedText((p) => !p)}>{t('timecodes_button')}</TextLink>
        </span>
      )}
    </TextWrapper>
  );
};
