import { CompleteStyled, CompleteWrapper, Title, ButtonsWrapper } from './Complete.styled';

import {useTranslation} from "react-i18next";

import { Button } from '@/components';
import { useActions, useAppSelector } from '@/hooks';

export const Complete = () => {
  const { setQuizRestart } = useActions();
  const { t } = useTranslation('pages');

  const [correctCount, questionsCount] = useAppSelector((state) => [
    state.quiz.correctCount,
    state.quiz.questions.length,
  ]);

  const setText = () => {
    const correctPercent = Math.round((correctCount / questionsCount) * 100);
    if (correctPercent === questionsCount) {
      return `${t('quizPage.correct_total_quiz')}`;
    } else {
      return `${t('quizPage.count_quiz', { correctCount: correctCount, questionsCount: questionsCount })}`;
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <CompleteStyled>
      <CompleteWrapper>
        {correctCount === questionsCount && <Title>{t('quizPage.result_title')}</Title>}
        <Title>{setText()}</Title>

        <ButtonsWrapper>
          <Button
            onClick={() => {
              setQuizRestart();
            }}
            style={{ padding: '12px 20px', fontSize: '20px' }}
            theme="white"
          >
            {t('quizPage.restart_quiz_btn')}
          </Button>
          <Button
            theme="white"
            style={{ padding: '12px 20px', fontSize: '20px' }}
            onClick={handleReload}
          >
            {t('quizPage.go_to_video_btn')}
          </Button>
        </ButtonsWrapper>
      </CompleteWrapper>
    </CompleteStyled>
  );
};
