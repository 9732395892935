import styled from 'styled-components';

import {Button} from '@/components';
import { ButtonText, theme } from '@/styles';

export const ContainerVideoPage = styled.div`
  margin-top: 40px;
`;
export const Wrapper = styled.div``;
export const Title = styled.p`
    font-weight: 500;
    font-size: 28px;
    line-height: 39px;
    color: ${theme.colors.text.white_100};

    @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 34px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
        line-height: 28px;
        //margin-left: 20px;
    }
`;

export const Inner = styled.div`
  //margin: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  /* height: 500px; */
  background-color: ${theme.colors.blue.blue_5};
  display: flex;
  justify-content: center;
    align-items: center;

  background-image: url('https://catherineasquithgallery.com/uploads/posts/2021-02/1612753659_156-p-fon-gradient-goluboi-fioletovii-180.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  box-shadow: 0px 4px 75px 50px rgba(11, 0, 44, 0.49);
`;

export const TabWrapper = styled.div<{ disabled: boolean }>`
    display: flex;
    border-radius: 12px;
    border: ${({disabled}) => (disabled ? '2px #685FA7 solid' : '2px white solid')}; ;
    max-width: max-content;
    opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`;

export const ButtonTab = styled(Button)<{ disabled: boolean }>`
  @media screen and (max-width: 768px) {
    padding: 12px 18px;
  }
`;

export const Panel = styled.div`
    position: relative;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  justify-items: flex-end;
  height: 51px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;

export const InfoBlockPlaylistMaterial = styled.div`
    position: absolute;
    z-index: 1;
    background-color: #16066C;
    border-radius: 10px;
    max-width: 500px;
    padding: 20px;
    top: 60px;
    right: 30px;
`

export const TextTab = styled(ButtonText)`
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const CardList = styled.div`
  position: relative;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
  gap: 20px;

  @media screen and (max-width: 768px) {
    justify-items: center;
  }
`;
