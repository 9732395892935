import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

type ParamsKey =
  | 'popup'
  | 'id'
  | 'page'
  | 'search'
  | 'limit'
  | 'offset'
  | 'search_str'
  | 'token'
  | 'uidb'
  | 'u'
  | 'sortSt'
  | 'accessor';

export const useAppSearchParams = () => {
  const [params, setParams] = useSearchParams();

  const getAppParam = (key: ParamsKey) => {
    return params.get(key);
  };

  const setAppParams = useCallback((key: ParamsKey, value: string) => {
    setParams({ ...params, [key]: value });
  }, [params, setParams]);

  const deleteAppParam = useCallback((key: ParamsKey) => {
    params.delete(key);
    setParams({ ...params });
  }, [params, setParams]);

  return { getAppParam, setAppParams, deleteAppParam, params, setParams };
};
