import { ButtonsWrapper, Inner } from './RemoveQuiz.styled';

import { Modal, Title } from '../Modal';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDeleteQuizMutation } from '@/api';
import { FullScreenLoader, Button } from '@/components';
import { useActions } from '@/hooks';

interface RemovePlaylistProps {
  publicId: string;
  playlistId: string;
}
export const RemoveQuiz = ({ publicId, playlistId }: RemovePlaylistProps) => {
  const { t } = useTranslation('components');
  const { closeModal, showNotification } = useActions();

  const [isDelete, setIsDelete] = useState<boolean>(false);

  const [deleteQuiz, { isLoading }] = useDeleteQuizMutation();

  const deletePL = async () => {
    try {
      await deleteQuiz({ publicId, playlistId }).unwrap();

      closeModal();

      showNotification({
        text: 'Квиз успешно удален',
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        text: 'Произошла ошибка при удалении квиза',
        severity: 'error',
      });
      setIsDelete(true);
    }
  };

  return (
    <Modal modal={'RemoveQuiz'} closeButton>
      <Inner>
        <Title>Удаление квиза</Title>
        <ButtonsWrapper>
          <Button theme={isDelete ? 'disabled' : 'red'} onClick={deletePL} disabled={isDelete}>
            {t('form.del')}
          </Button>
          <Button onClick={() => closeModal()} theme="inline">
            {t('form.cancel')}
          </Button>
        </ButtonsWrapper>
        {isLoading && <FullScreenLoader />}
      </Inner>
    </Modal>
  );
};
