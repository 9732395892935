import { QuizStyled, Text } from './Quiz.styled';

import { Complete } from '../Complete';
import { Question } from '../Question/Question';

import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useGetVideoAllQuizzesQuery } from '@/api';
import FullScreenLoader from '@/components/Shared/FullScreenLo/FullScreenLoader';
import { useAppSelector, useHandlingError } from '@/hooks';
import {StatusType} from "@/types";

interface QuizProps {
  publicId?: string;
  playlistId?: string;
  goToTime: (time: number) => void;
  hash?: string;
  videoPublicId?: string;
  isVideoFile?: boolean;
  playlistType?: string;
  quizStatus?: StatusType | undefined;
}

export const Quiz = ({
                       publicId = '',
                       playlistId = '',
                       goToTime,
                       hash,
                       videoPublicId = '',
                       isVideoFile = false,
                       quizStatus,
}: QuizProps) => {
  const { t } = useTranslation('loading');


  // const { data, isLoading, error, isError } = useGetVideoQuizQuery(
  //   { playlistId, publicId },
  //   { skip: Boolean(hash) || !publicId || !playlistId || isVideoFile },
  // );

  const {
    data,
    isLoading,
    isError,
    error,
  } = useGetVideoAllQuizzesQuery({ playlistId, videoPublicId, hash }, { skip: Boolean(publicId) || isVideoFile });

  // const {
  //   data: fileQuizz,
  //   isLoading: isFileQuizzLoading,
  //   isError: isFileQuizzError,
  //   error: fileQuizzError,
  // } = useGetAllFileQuizzesQuery({ public_id: videoPublicId }, { skip: !isVideoFile });

  const [activeQuestionIndex, questions, done] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
    state.quiz.done,
  ]);

  const { catchError } = useHandlingError();


  useEffect(() => {
    catchError(error );
  }, [error]);

  if ( isError || quizStatus === "NOT_STARTED" ) {
    return null;
  }

  return (
    <QuizStyled>
      {isLoading && <FullScreenLoader />}
      {quizStatus === "IN_PROGRESS" && !questions && <Text>{t('load_quiz')}</Text>}
      {data && !done && (
        <Question {...questions[activeQuestionIndex]} goToTime={goToTime} />
      )}
      {done && <Complete />}
    </QuizStyled>
  );
};
