import { forwardRef, useImperativeHandle, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';
import YouTube, { type YouTubeEvent, type YouTubeProps } from 'react-youtube';

let timerId: number;

export const YouTubeVideoPlayer = forwardRef<
  { goToTime: (time: number) => void },
  { id: string; title: string; startsFrom?: number; height: string; setCurrentTime: (time: number) => void }
>(({ id, title, startsFrom = 0, height, setCurrentTime }, ref) => {
  const [params] = useSearchParams();

  const iframe = useRef<YouTube>(null);

  const goToTimeFunc = async (event: YouTubeEvent) => {
    await event.target.seekTo(params.get('t') ?? 0, true);
    await event.target.playVideo();
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        goToTime: (time: number) => {
          iframe.current?.internalPlayer.seekTo(time, true);
        },
      };
    },
    [],
  );

  /**
   * Function to handle state change events from the YouTube player. When the state
   * changes to "playing" (1), start an interval to update the current time every
   * second. When the state changes to "paused" (2), stop the interval.
   * @param {YouTubeEvent} event The event object from the YouTube player.
   */
  const onStateChange: YouTubeProps['onStateChange'] = (event: YouTubeEvent) => {
    if (event.data === 1) {
      timerId = setInterval(async () => {
        const currentTime = (await iframe.current?.internalPlayer.getCurrentTime()) ?? 0;
        setCurrentTime(currentTime);
      }, 1000);
    }

    if (event.data === 2) {
      clearInterval(timerId);
    }
  };

  return (
    <YouTube
      videoId={id}
      title={title}
      ref={iframe}
      style={{ width: '100%', height }}
      onStateChange={onStateChange}
      onReady={goToTimeFunc}
      opts={{
        width: '100%',
        height,
        playerVars: {
          start: startsFrom,
          autoplay: 1,
          rel: 0,
        },
      }}
    />
  );
});

YouTubeVideoPlayer.displayName = 'YouTubeVideoPlayer';
