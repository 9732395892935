import styled from 'styled-components';
import {theme} from "@/styles";

type HeaderStyleProps = {
  isOpen?: boolean;
};

export const HeaderStyled = styled.header``;

export const Inner = styled.div`
    display: grid;
    grid-template-columns: minmax(48px, 64px) 1fr max-content 78px;
    align-items: center;
    padding-top: 20px;
    width: 100%;
    
    @media (max-width: 725px) {
        position: fixed;
        z-index: 10;
        padding-bottom: 10px;
        background-color: ${theme.colors.blue.blue_modal};
        width: 95%;
    }
    
    @media (max-width: 417px) {
        width: 95%;
        padding-right: 20px;
    }
    
    @media (max-width: 1024px) {
        grid-template-columns: minmax(48px, 64px) 1fr 78px;
        min-height: 94px;
    }
`;

export const InputWrapper = styled.div<HeaderStyleProps>`
  padding: 0 10px 0 39px;
  max-width: 534px;
  button {
    display: none;
  }

  @media (max-width: 1250px) {
    padding-left: 10px;
  }

  @media (max-width: 1024px) {
    position: absolute;
    /* display: ${(props) => (props.isOpen ? 'block' : 'none')}; */
    width: 100%;
    padding: 0;
    margin: 0;
    top: 25px;
    right: 20px;
    left: 2px;
    z-index: 10;
    //width: auto;
    transition: all 0.5s;

    transform: ${(props) => (props.isOpen ? 'translateY(0%)' : 'translateY(-200%)')};
    /* opacity: ${(props) => (props.isOpen ? '1' : '0')}; */

    button {
      display: block;
      position: absolute;
      right: 15px;
      top: 14px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-right: 48px;
  @media (max-width: 1024px) {
    margin-right: 77px;
    justify-self: flex-end;
  }
  @media (max-width: 960px) {
    margin-right: 48px;
  }
  @media (max-width: 768px) {
    margin-right: 36px;
  }

  button:nth-child(1) {
    display: none;
  }

  button:nth-child(4) {
    display: none;
  }

  @media (max-width: 1200px) {
    button:nth-child(3) {
      display: block;
    }
  }

  @media (max-width: 1024px) {
    /* margin-left: auto; */
    gap: 15px;
    button:nth-child(1) {
      display: block;
    }
  }

  @media (max-width: 725px) {
    gap: 0;
    button:nth-child(n + 2) {
      display: none;
    }
    button:nth-child(4) {
      display: block;
    }
  }

  @media (max-width: 480px) {
    button:nth-child(2) {
      display: none;
    }
  }
`;
