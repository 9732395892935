import {
  VideoCardWrapper,
  InnerCard,
  // VideoTime,
  PlayButton,
  Title,
  FragmentTime,
  ImageFragmentWrapper,
} from './VideoFragmentCard.styled';

import PlayIcon from '../../SvgIcons/PlayIcon';

import { memo } from 'react';

import { VideoFragmentCardProps } from '@/components/Card';
import { getImageUrl, secondsToTime } from '@/utils';

const VideoFragmentCard = ({
  fragment: { content, timestampLink, image },
  goToTime,
  videoPreview,
}: VideoFragmentCardProps) => {
  const startsFrom = parseInt(timestampLink);

  const goToHandler = () => {
    goToTime?.(startsFrom);
  };

  return (
    <VideoCardWrapper onClick={goToHandler}>
      <ImageFragmentWrapper bgImage={(image && getImageUrl(image)) || videoPreview || '/images/playlist_mock.png'}>
        <PlayButton>
          <PlayIcon />
        </PlayButton>
        {/* <VideoTime>
          {new Date(startsFrom * 1000).toLocaleTimeString('ru-RU', {
            second: '2-digit',
            minute: '2-digit',
          })}
        </VideoTime> */}
      </ImageFragmentWrapper>
      {/* <DescriptionMenuWrapper>
          </DescriptionMenuWrapper> */}
      <InnerCard>
        <FragmentTime>{secondsToTime(startsFrom)}</FragmentTime>
        <Title dangerouslySetInnerHTML={{ __html: content }} />
      </InnerCard>
    </VideoCardWrapper>
  );
};

export default memo(VideoFragmentCard);
