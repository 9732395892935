import styled, { css } from 'styled-components';

import { Button } from '@/components';
import { TitleH4, theme } from '@/styles';

export const Wrapper = styled.div`
    background: #16066c;
    border-radius: 20px;
    padding: 30px 30px 75px;
    min-height: 250px;
    position: relative;
`;

export const TimecodesWrapper = styled.div<{ isCollapsed: boolean }>`
    position: relative;
    height: 247px;
    //overflow-y: scroll;
    overflow: hidden;
    background: #16066c;
    border-radius: 20px;
    padding: 30px 30px 75px;
    transition: height 0.5s ease-in-out;
    ${(props) =>
            props.isCollapsed &&
            css`
                height: max-content;
            `} @media (
    max-width: 1028px) {
    padding: 20px 20px 75px;
}
    @media (max-width: 768px) {
        padding: 10px 10px 75px;
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        width: 10px; /* ширина scrollbar */
        overflow: hidden;
    }

    ::-webkit-scrollbar-track {
        background: transparent; /* цвет дорожки */
        overflow: hidden;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 20px; /* закругления плашки */
        background-color: ${theme.colors.blue.blue_5}; /* цвет плашки */
    }
`;
export const Title = styled(TitleH4)``;

export const TimecodesTitle = styled(TitleH4)`
  font-weight: 600;
  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const TimecodesContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;
  width: 84%;

  @media (max-width: 768px) {
    width: 98%;
    gap: 5px;
  }
`;

export const TimecodesTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }

  :active {
    scale: 0.99;
  }
  transition: opacity 0.25s ease-in-out;
`;

export const List = styled.ul`
`;

export const ListItem = styled.li`
  display: flex;
    flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
`;

export const Time = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #dc81ff;
  cursor: pointer;
  width: 120px;
  padding: 7px;
  border-radius: 5px;
  flex-shrink: 0;
  transition: color 0.4s ease-in-out;
  @media (max-width: 1028px) {
    width: 70px;
    font-size: 20px;
    padding: 5px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    width: 50px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  padding-left: 80px;

  @media (max-width: 480px) {
    padding-left: 60px;
  }
`;

export const Text = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const TextLink = styled.span`
  margin-left: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  color: ${theme.colors.text.white_100};

  @media (max-width: 1028px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
    line-height: 14px;
  }
  cursor: pointer;
  :hover {
    color: ${theme.colors.text.white_80};
  }
  transition: color 0.35s ease-in-out;
`;

export const MoreButton = styled(Button)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  @media (max-width: 1028px) {
    font-size: 15px;
    padding: 10px 20px;
  }
  @media (max-width: 768px) {
    background-color: #210071;
    font-size: 13px;
    padding: 5px 10px;
    bottom: 0px;
    right: 0;
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: flex-end;
    gap: 5px;
  }
`;
