import { useActions } from './useActions';

import { isFetchBaseQueryError, isSerializedError } from '../utils';
import { isAuthData } from '../utils/isAuthData';

import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useHandlingError = () => {
  const { t } = useTranslation('error');
  const navigate = useNavigate();
  const { logout, showNotification } = useActions();
  // const location = useLocation();

  const ErrorLogout = useCallback((code: string | number, message = '', data?: unknown) => {
    switch (code) {
      case '401':
      case 401:
      case '403':
      case 403:
        if (isAuthData(data)) {
          if ('code' in data) {
            logout();
            setTimeout(() => {
              showNotification({
                text: `${t('refresh')} ${message}`,
                severity: 'error',
              });
            }, 1000);
            navigate('/', { replace: true });
            // if(store.getState().user.isAuth){

            // }
          }
          // else {
          //   data.detail.split('').includes('email') ? t('try_l', { ns: 'error' }): t('incor_cred', { ns: 'error' })
          //   showNotification({
          //     text: `${t('incor_cred')} ${message}`,
          //     severity: 'error',
          //   });
          // }
        }
        //   dispatch(updateUser({ user: undefined }));
        //   dispatch(boardsAPI.util.resetApiState());

        break;
      case '500':
      case 500:
        showNotification({
          text: `${t('500')}. ${message}`,
          severity: 'error',
        });
        break;
      case 'FETCH_ERROR':
        showNotification({
          text: `${t('fetch')}. ${message} `,
          severity: 'error',
        });
        break;
      case '404':
      case 404:
        // dispatch(showNotification({
        //   text: '404',
        //   severity: 'error',
        // }))
        showNotification({
          text: `${t('404')}. ${message} `,
          severity: 'error',
        });
        // navigate('/404', { replace: true });
        break;
      default:

        showNotification({
          text: `${code} ${message}`,
          severity: 'error',
        });
    }
  }, [t, showNotification, logout, navigate]);

  const catchError = useCallback(
    (error: unknown, text?: string) => {
      if (isFetchBaseQueryError(error)) {
        const code = error?.status as string;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const message = error?.data?.message || '';
        ErrorLogout(code, text || message, error.data);

      } else if (isSerializedError(error)) {
        const message = error?.message as string;
        const code = error?.code as string;
        ErrorLogout(code, message);
      }
    },
    [ErrorLogout],
  );

  return { catchError };
};
