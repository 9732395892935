import { IconWrapper, StyledInput, Wrapper } from './SearchInVideoInputStyle';

import { useDebounce } from '@/hooks';
import SearchIcon from '../../SvgIcons/SearchIcon';

import { useEffect, useRef } from 'react';

import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface SearchInVideoInputProps {
  getSearch: (value: string) => Promise<void>;
  disabled?: boolean;
  placeholder?: string;
}

const SearchInVideoInput = ({ getSearch, disabled = false, placeholder }: SearchInVideoInputProps) => {
  const { t } = useTranslation('components');
  const [param, setParam] = useSearchParams();
  const search = param.get('search') || '';

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const data = searchInputRef.current?.value || '';

    if (data) {
      getSearch(data);
    }
  }, []);

  const makeSearch = useDebounce(() => {
    const data = searchInputRef.current?.value || '';
    if (data) {
      setParam((prev) => {
        prev.set('search', data);
        return prev;
      });
      getSearch(data);
      ReactGA.event({
        category: 'Search',
        action: 'Search in playlist',
      });
    } else {
      setParam((prev) => {
        prev.delete('search');
        return prev;
      });
    }
  }, 500);

  const onSearch = () => {
    makeSearch();
  };

  return (
    <Wrapper>
      <StyledInput
        placeholder={placeholder ?? t('search_in_video')}
        ref={searchInputRef}
        onChange={onSearch}
        defaultValue={search}
        disabled={disabled}
        icon={
          <IconWrapper>
            <SearchIcon />
          </IconWrapper>
        }
      />
    </Wrapper>
  );
};

export default SearchInVideoInput;
