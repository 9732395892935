import {getMinutes} from './helpers';
import {TimecodeDiscription} from './TimecodeDiscription';
import {
  Time,
  TimecodesWrapper,
  Title,
  TimecodesContentWrapper,
  TimecodesTitleWrapper,
  List,
  ListItem,
  Text,
  MoreButton,
  TimecodesTitle,
} from './Timecodes.styled';

import {memo, useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';

import {useGetTimecodesQuery} from '@/api';
import {FullScreenLoader} from '@/components';
import {useHandlingError} from '@/hooks';
import {StatusType} from "@/types";

interface TimecodesProps {
  setTime: (time: number) => void;
  id: string;
  currentTime?: number | null;
  hash?: string;
  timecodesStatus: StatusType | undefined;
}

export const Timecodes = memo(
  ({setTime, id, currentTime, hash, timecodesStatus}: TimecodesProps) => {
    const {t} = useTranslation('error');
    const [isCollapsed, setIsCollapsed] = useState(true);
    const {catchError} = useHandlingError();

    const {data, isLoading, isError, error} = useGetTimecodesQuery(
      {videoPublicId: id ?? '', hash},
    );

    // const {
    //   data: summaries,
    //   isError: isSummariesError,
    //   isLoading: isSummariesLoading,
    // } = useGetAllShortSummaryQuery({public_id: id ?? ''});

    const timings = data?.map((array) => array.start) || [];

    const highlightChapter = (i: number) => {
      // TODO: make a scroll to current timecodes
      // if (currentTime && currentTime >= timings[i] && currentTime < timings[i + 1]) {
      //   refs?.[i]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      // }
      return (
        currentTime &&
        currentTime >= (timings[i] as number) &&
        (timings[i + 1] === undefined || currentTime < (timings[i + 1] as number))
      );
    };

    useEffect(() => {
      if (error) {
        catchError(error);
      }
    }, [error]);

    const onReadMoreClick = () => {
      setIsCollapsed(!isCollapsed);
    };

    if (timecodesStatus === "NOT_STARTED" && (data && data.length === 0)) return null;

    return (
      <>
        {data &&
            <TimecodesWrapper isCollapsed={isCollapsed}>
                <TimecodesContentWrapper>
                  {isLoading && <FullScreenLoader/>}
                  {isError && <Text>{t('load_timecodes', {ns: 'loading'})}</Text>}
                  {timecodesStatus === "IN_PROGRESS" && data.length === 0 && (
                    <Text>{t('load_timecodes', {ns: 'loading'})}</Text>
                  )}

                  {/*{!summaries || (summaries && summaries.results.length === 0) && (*/}
                  {/*  <Text>{t('load_timecodes', {ns: 'loading'})}</Text>*/}
                  {/*)}*/}

                  {data && data.length > 0 && (
                    <>
                      <Title>{t('timecodes', {ns: 'components'})}</Title>
                      <List>
                        {data.map(({start, text, title}, i) => (
                          <ListItem key={i}>
                            {/*  ref={refs?.[i]} */}

                            <TimecodesTitleWrapper onClick={() => setTime(start as number)}>
                              <Time>{getMinutes(start as number)}</Time>
                              <TimecodesTitle
                                style={{backgroundColor: highlightChapter(i) ? '#BE9F4B' : 'transparent'}}>
                                {title}
                              </TimecodesTitle>
                            </TimecodesTitleWrapper>
                            <TimecodeDiscription text={text}/>

                          </ListItem>
                        ))}
                      </List>
                      <MoreButton theme="inline" onClick={onReadMoreClick}>
                        {isCollapsed
                          ? t('timecodes_button', {ns: 'components'})
                          : t('timecodes_button_more', {ns: 'components'})}
                      </MoreButton>
                    </>
                  )}
                </TimecodesContentWrapper>
            </TimecodesWrapper>
        }
      </>
    );
  },
);

Timecodes.displayName = 'Timecodes';
