import {
  AnswersWrapper,
  QuestionWrapper,
  Title,
  VisibleAnswer,
  ControlButton,
  ControlWrapper,
  QuestionHeader,
  SeekToButton,
  TitleWrapper,
  QuestionBody,
} from './Question.styled';

import {useTranslation} from "react-i18next";

import { ArrowLeft } from '@/components/SvgIcons';
import ArrowRight from '@/components/SvgIcons/ArrowRight';
import { useActions, useAppSelector } from '@/hooks';


interface QuestionProps {
  question: string;
  answers: string[];
  correctAnswer: string;
  answer: string | null;
  goToTime: (time: number) => void;
  start: number | string;
}
export const Question = ({
  answers,
  correctAnswer,
  answer: currentAnswer,
  question,
  goToTime,
  start,
}: QuestionProps) => {
  const { t } = useTranslation('components')
  const { setQuizAnswer, setActiveQuestionIndex, setQuizDone } = useActions();
  const [activeQuestionIndex, questions] = useAppSelector((state) => [
    state.quiz.activeQuestionIndex,
    state.quiz.questions,
  ]);
  // const isLastQuestion = activeQuestionIndex === questions.length - 1;

  const handleAnswer = (answer: string) => {
    if (!currentAnswer) {
      setQuizAnswer({ question, answer });
    }
  };

  const onNextButtonHandler = () => {
    if (!questions.filter((q) => q.answer === null).length) {
      setQuizDone();
    }
    questions.length > activeQuestionIndex + 1 && setActiveQuestionIndex(activeQuestionIndex + 1);
  };

  return (
    <QuestionWrapper>
      <QuestionHeader>
        <TitleWrapper>
          <Title>{question}</Title>
          <span>{`${t('quizPage.question_quiz',
            { ns: 'pages', activeQuestionIndex: activeQuestionIndex + 1, questionsLength: questions.length })}`}</span>
        </TitleWrapper>
        <ControlWrapper>
          <ControlButton
            onClick={() => {
              setActiveQuestionIndex(activeQuestionIndex - 1);
            }}
            disabled={!activeQuestionIndex}
          >
            <ArrowLeft />
          </ControlButton>
          <ControlButton onClick={onNextButtonHandler}>
            <ArrowRight />
          </ControlButton>
        </ControlWrapper>
      </QuestionHeader>
      <QuestionBody>
        <AnswersWrapper>
          {answers.map((answer, index) => (
            <VisibleAnswer
              key={index}
              answerType={currentAnswer === answer ? (answer === correctAnswer ? 'correct' : 'incorrect') : undefined}
              onClick={() => handleAnswer(answer)}
              disabled={!!currentAnswer}
            >
              {answer}
            </VisibleAnswer>
          ))}
        </AnswersWrapper>
        {currentAnswer && correctAnswer !== currentAnswer && (
          <SeekToButton theme="white" onClick={() => goToTime(start as number)}>
            {t('question_watch_video')}
          </SeekToButton>
        )}
      </QuestionBody>
    </QuestionWrapper>
  );
};
