import styled from 'styled-components';

import { theme } from '@/styles';

export const QuizStyled = styled.div`
  position: relative;
  min-height: 345px;
  gap: 15px;
  padding: 20px 30px;
  border-radius: 20px;
  background-color: ${theme.colors.blue.blue_20};
`;

export const Text = styled.span`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: ${theme.colors.text.white_80};

  @media (max-width: 1028px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
