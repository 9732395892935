import { ChangeEvent, DragEventHandler, FormEvent, useEffect, useRef, useState } from "react";

import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { playlistsAPI, useCreateVideoFileMutation, videoFilesAPI, videosAPI } from "@/api";
import { useActions } from "@/hooks/useActions";
import { useMatchMedia } from "@/hooks/useMatchMedia";
import { useAppSelector } from "@/hooks/useStore";
import { FileParams } from "@/types";
import { removeQueryParams } from "@/utils/deleteSearchParamFromURL";
import {combinedRegex, vkRegex, youtubeRegex} from "@/utils/regExSoursesLink";

export const useAddVideo = () => {
  const { t } = useTranslation('modal');
  const [videoLinkValue, setVideoLinkValue] = useState<string>('');
  const [selectedPlaylist, setSelectedPlaylist] = useState('');
  const { closeModal, showNotification } = useActions();
  const [, modal] = useAppSelector((state) => [state.user.isAuth, state.modal.active]);
  const isCommercial = useAppSelector((state) => state.user.isCommercial)
  const { data: playlists } = playlistsAPI.useGetMyPlaylistsQuery({}, { skip: modal !== 'AddVideo' });
  const [addVideoToPlaylist] = playlistsAPI.useAddVideoToPlaylistMutation();
  const [createMovie, result] = videosAPI.useCreateVideoMutation();

  const [isLinkError, setIsLinkError] = useState(false);
  const [isOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [incorrectVideoFormat, setIncorrectVideoFormat] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [presignedUrl, setPresignedUrl] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoaging] = useState(false);
  const [, setIsFileUploaded] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isAborted, setIsAborted] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const isTablet = useMatchMedia('(max-width: 768px)');
  const [createVideoFile] = useCreateVideoFileMutation();
  const [fileParams, setFileParams] = useState<FileParams | null>(null);

  const { data: linkData, error: linkError } =
    videoFilesAPI.useGetLinkUploadVideoFileQuery(
      { file_type: fileParams?.file_type ?? '', file_name: fileParams?.file_name ?? '' },
      { skip: modal !== 'AddVideo' || fileParams === null }
    );

  const allowedFormats: { [key: string]: string } = {
    'mp4': '.mp4',
    // 'avi': '.avi',
    // 'mkv': '.mkv'
  };

  useEffect(() => {
    if (selectedFile) {
      const fileType = selectedFile.type.replace('video/', '');
      const format = allowedFormats[fileType] || '';

      const fileName = selectedFile.name.replace(/\.(mp4)$/, '');
      // const fileName = selectedFile.name.replace(/\.(mp4|avi|mkv)$/, '');
      setFileParams({ file_type: format, file_name: fileName });
    }
  }, [selectedFile]);


  useEffect(() => {
    if (linkData) {
      setPresignedUrl(linkData.presignedUrl);
      setUserId(linkData["x-amz-meta-userId"])
    } else if (linkError) {
      setIsError(true)
      showNotification({
        text: t('error', { ns: 'error' }),
        severity: 'error',
      });
      setIsFileUploaded(false);
      setSelectedFile(null)
    }
  }, [linkData]);

  const closeHandler = () => {
    closeModal();
    setVideoLinkValue('');
    setIsLoaging(false);
    setSelectedPlaylist('');
    setIsLinkError(false);
    setIsError(false);
    setIncorrectVideoFormat(false);
    setSelectedFile(null);
    setIsAborted(false);
    setPresignedUrl('');
    setIsSuccess(false);
  };

  const addVideo = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (videoLinkValue.length) {
      const newVideo = await createMovie({ originLink: videoLinkValue });
      if ('data' in newVideo) {
        closeHandler();
        showNotification({
          text: t('add_video', { ns: 'success' }),
          severity: 'success',
          link: `/library/videos/watch/${newVideo.data.publicId}?t=0`,
        });
        ReactGA.event({ category: 'video', action: 'add_video_to_library' });
      }
    }

    if (selectedFile && videoLinkValue === '') {
      setIsLoaging(true)
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        if (linkData) {
          const validLink = removeQueryParams(linkData.presignedUrl);
          const response = await createVideoFile({ link: validLink ?? '' }).unwrap();
          setIsFileUploaded(true);
          closeHandler();
          showNotification({
            text: t('add_video', { ns: 'success' }),
            severity: 'success',
            link: `/library/videos/watch/${response.publicId}?t=0`,
          });
        }
      } catch (error) {
        setIsError(true)
        showNotification({
          text: t('error', { ns: 'error' }),
          severity: 'error',
        });
        setIsFileUploaded(false);
        setSelectedFile(null)
      } finally {
        setIsLoaging(false)
      }

      ReactGA.event({ category: 'files', action: 'add_video_to_library' });
    }

    if (videoLinkValue.length && selectedPlaylist) {
      const newVideoId = await createMovie({ originLink: videoLinkValue });
      if ('data' in newVideoId) {
        ReactGA.event({ category: 'video', action: 'add_video' });
        const payload = await addVideoToPlaylist({
          playlistId: selectedPlaylist,
          videos: [
            {
              videoPublicId: newVideoId.data.publicId,
              isAiSuggested: false,
            },
          ],
        });
        if ('data' in payload) {
          closeHandler();
          showNotification({
            text: t('add_video_pl', { ns: 'success' }),
            severity: 'success',
            link: `/playlists/${payload.data.publicId}`,
          });
        } else if ('status' in payload.error) {
          setIsError(true)
          showNotification({
            text: t('error', { ns: 'error' }),
            severity: 'error',
          });
        } else {
          setIsError(true)
          showNotification({
            text: t('error', { ns: 'error' }),
            severity: 'error',
          });
        }
      }
    }
  };

  const videoLinkHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();
    setVideoLinkValue(trimmedValue);
    setIsError(false);
    setIsLinkError(false);

    const isYouTube = youtubeRegex.test(trimmedValue);
    const isVK = vkRegex.test(trimmedValue);
    // const isKinescope = kinescopeRegex.test(trimmedValue);
    const isValidResourceLink = combinedRegex.test(trimmedValue);

    if (isValidResourceLink) {
      if (!isYouTube && !isVK) {
        setIsError(true);
        setIsLinkError(false);
      }
    } else {
      setIsLinkError(true);
      setIsError(false);
    }
  };

  const handleFilePrompt = () => {
    console.log('handleFilePrompt');
    if (videoLinkValue === '') {
      console.log(videoLinkValue, fileInputRef.current);
      fileInputRef.current?.click();
    }
  };

  const validateFile = (file: File) => {
    const maxFileSize = 5 * 1024 * 1024 * 1024;

    if ((file.type === 'video/mp4' || 'video/avi' || 'video/mkv') && file.size <= maxFileSize) {
      setIncorrectVideoFormat(false);
      setSelectedFile(file);
    } else {
      setIncorrectVideoFormat(true);
      setSelectedFile(null);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    const file = event.dataTransfer?.files[0];
    if (file) validateFile(file);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
  };


  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('onFileChange');
    const file = e.target.files?.[0];
    if (file) {
      validateFile(file);
      e.target.value = '';
    }
  };

  const deleteVideo = () => {
    setSelectedFile(null);
    setIsFileUploaded(false);
  }

  const options = playlists?.results.map((playlist) => ({
    value: playlist.publicId,
    label: playlist.title,
    isPrivate: playlist.privacyType === "private"
  })) || [];

  const stylesSelect = {
    control: (base: any) => ({
      ...base,
      width: '100%',
      color: '#62548B',
      cursor: 'pointer',
      border: '2px solid #62548B',
      borderRadius: '10px',
      height: '52px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '&:hover': {
        border: '2px solid #62548B',
      }
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: '#140150',
    }),
    option: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.isSelected ? 'rgb(22, 6, 108)' : '#140150',
      color: options.length !== 0 ? '#ffffff' : '#b2b2ee',
      fontWeight: '300',
      borderRadius: '10px',
      fontSize: options.length !== 0 ? '17px' : '14px',
      lineHeight: options.length !== 0 ? '24px' : '20px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: options.length !== 0 ? 'rgb(22, 6, 108)' : '',
      }
    }),
    placeholder: (base: any) => ({
      ...base,
      color: '#62548B',
      fontWeight: '300',
      fontSize: '17px',
      lineHeight: '24px',
    }),
    singleValue: (base: any) => ({
      ...base,
      color: '#ffffff',
      fontWeight: '300',
      fontSize: '17px',
      lineHeight: '24px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      color: '#62548B',
    }),
    dropdownIndicator: (base: any, { isDisabled }: any) => ({
      ...base,
      color: !isDisabled ? '#b2b2ee' : '#62548B',
      transition: 'color 0.2s ease',
    }),
  }

  return {
    videoLinkValue,
    selectedPlaylist,
    isLinkError,
    isError,
    selectedFile,
    isLoading,
    videoLinkHandler,
    addVideo,
    closeHandler,
    setSelectedPlaylist,
    setSelectedFile,
    deleteVideo,
    handleDrop,
    handleDragOver,
    onFileChange,
    handleFilePrompt,
    playlists,
    t,
    setVideoLinkValue,
    fileInputRef,
    incorrectVideoFormat,
    isTablet,
    isOpen,
    result,
    stylesSelect,
    options,
    isCommercial,
    presignedUrl,
    setPresignedUrl,
    userId,
    setIsError,
    isSuccess,
    setIsSuccess,
    isAborted,
    setIsAborted,
    setShowInfo,
    showInfo,
  }
};