import styled from 'styled-components';

import { TitleH4 } from '@/styles';

export const CompleteStyled = styled.div`
  display: flex;
  min-height: 300px;

  align-items: center;
  justify-content: center;
`;

export const CompleteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
`;

export const Title = styled(TitleH4)`
  font-weight: 700;
  text-transform: uppercase;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 568px) {
    button {
      width: 100%;
    }
  }
`;
