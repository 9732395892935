import { baseQueryWithReauth } from './baseQueryWithReauth';

import { createApi } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'visaver',
  tagTypes: [
    'stat',
    'user',
    'userMe',
    'userById',
    'videos',
    'playlist',
    'playlist_relation',
    'playlists',
    'personal_playlist',
    'personal_playlists',
    'personal_playlist_relation',
    'private_playlist',
    'searchAI',
    'searchInPlaylist',
    'categories',
    'quiz',
    'video_quizzes',
    'quizzes',
    'control_panel_quiz',
    'control_panel_summaries',
    'video-files',
    'video-file'
  ],
  baseQuery: baseQueryWithReauth(),
  endpoints: () => ({}),
  refetchOnReconnect: true,
  // refetchOnFocus: true,
});
