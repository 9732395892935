import { BreadCrumbLink, BreadCrumbsList, Crumb } from './BreadCrumbs.styled';

import { memo, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { generateBreadcrumbs } from '@/utils/generateBreadcrumbs';

export type BreadcrumbsType = {
  label: string;
  path: string;
};

export const BreadCrumbs = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('components');
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType[]>([]);

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((item) => item !== '');
    const updatedBreadcrumbs = generateBreadcrumbs(t, pathnames);

    if (pathnames.length > 2 && !pathnames.includes('quiz')) {
      updatedBreadcrumbs.push({
        label: t('breadcrumbsItems.toVideo'),
        path: `/watch`,
      });
    }
    setBreadcrumbs(updatedBreadcrumbs);
  }, [location.pathname, t]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <BreadCrumbsList>
      {breadcrumbs.map(({ label, path }, index) => {
        const isActive = index === breadcrumbs.length - 1;
        return (
          <Crumb key={index}>
            {isActive ? (
              label
            ) : (
              <BreadCrumbLink key={index} onClick={() => handleNavigation(path)} to={path}>
                {label}
              </BreadCrumbLink>
            )}
          </Crumb>
        );
      })}
    </BreadCrumbsList>
  );
});

BreadCrumbs.displayName = 'BreadCrumbs';
