import { lazy } from 'react';

export { default as Library } from './LibraryPage';
export const LibraryLazy = lazy(() => import('./LibraryPage'));

export * from './LibraryPage.styled';
export * from './PlaylistWrapper';
export * from './VideosWrapper';
export * from './FilesWrapper';

